import { ReactComponent as Isotipo } from '../../assets/img/isotipo.svg';
import { ReactComponent as Logotipo } from '../../assets/img/logo.svg';



import './index.css';

export const Logo = () => {
    return (
        <div className='logo' >
            <Isotipo className='isotipo'/>
            <Logotipo className='logotipo' />
        </div>
    )
}