import { gsap } from "gsap";
import CursorFunctions from "../Cursor/CursorFunctions";
import { NavBar } from "../NavBar";


import './index.css';

export const MenuLink = (props) => {

    function handleClick () {

        props.setOpen(false);
        props.setColorNav(props.bar);

        gsap.to('#navigation-content',{display:"none",delay:.7});
        gsap.to('#navigation-content',{y:'-100%',delay:.7});
        
        gsap.to('.contentBlock',{display:"none"});
            
        gsap.to('#breaker',{display:"block"});
        gsap.to('#breaker-two',{display:"block",delay:.1});
        gsap.to('#breaker',{display:"none",delay:1});
        gsap.to('#breaker-two',{display:"none",delay:1.5});
        
        gsap.to(props.dataTarget,{display:"block",delay:.7});
       
        gsap.to('#navigation-content',{display:'flex',delay:2});

    }
    
    return (
        <a className={props.cta} href={props.href} data-text={props.dataText} id={props.id} data-target={props.dataTarget} onClick={handleClick} onMouseEnter={CursorFunctions.cursorHover} onMouseLeave={CursorFunctions.cursor}>{props._text}</a>
    )
}