import { gsap } from "gsap";

import { useState } from "react";

import { MenuLink } from "../NavContent/MenuLink";
import { Logo } from "../Logo";

import CursorFunctions from "../Cursor/CursorFunctions";

import arrow from "../../assets/img/up-right-arrow.png";

import './index.css';
import { Container } from "react-bootstrap";

import { NavContent } from "../NavContent";


export const NavBar = (props) => {
    
    
    const [isOpen, setOpen] = useState(false);
    //const [colorNav, setColorNav] = useState("dark");
    const [prevColor, setPrevColor] = useState ("dark");


    const menuHandler = () => {
        setOpen(!isOpen);
        gsap.to('#navigation-content',{y:(isOpen ? "-100%" : 0), delay:.3});
        setPrevColor(props.colorNav);
        props.setColorNav(isOpen ? prevColor : "dark");
    };
    

    return (
        <div>
            <NavContent isOpen={isOpen} setOpen={setOpen} colorNav={props.colorNav} setColorNav={props.setColorNav}/>
            <div id="navigation-bar" className={"navigationBar " + props.colorNav}>
                <Container className="navContainer">
                    <MenuLink key={0} href="#" dataText="" dataTarget="#header" id="logoLinkHeader" _text={<Logo />} bar="dark" cta="" isOpen={isOpen} setOpen={setOpen} colorNav={props.colorNav} setColorNav={props.setColorNav}/>
                    <div className={"menuBox " + (isOpen ? "open" : "")}>
                        <div className="barCta">Descubre más <img className="arrow" src={arrow} alt="arrow" /></div>
                        <div className="menubar"  onClick={menuHandler} onMouseEnter={CursorFunctions.cursorHover} onMouseLeave={CursorFunctions.cursor}>
                            <span className="first-span"></span>
                            <span className="second-span"></span>
                            <span className="third-span"></span>
                        </div>
                    </div>
                </Container>  
            </div>
        </div>
    )
}