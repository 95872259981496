import { gsap } from "gsap";

import { Logo } from "../Logo";

import { MenuLink } from './MenuLink.js';

import CursorFunctions from "../Cursor/CursorFunctions";


import './index.css';

export const NavContent = ({isOpen, setOpen, colorNav, setColorNav}) => {
    
    const menuList = [
        {text:"INICIO", target:"#header", href:"#", id:"home-link", bar:"dark", cta:""},
        {text:"ACERCA DE MI", target:"#about", href:"#", id:"about-link", bar:"light", cta:""},
        {text:"PORTAFOLIO", target:"#portfolio", href:"#", id:"portfolio-link", bar:"light", cta:""},
        {text:"CONTACTAME", target:"#contact", href:"#", id:"contact-link", bar:"light", cta:"cta"},
    ]
    
    return (
        <div id="navigation-content">
            <div className="navigation-links">
                {menuList.map((item, index) => (
                    <MenuLink key={index} href={item.href} dataText={item.text} dataTarget={item.target} id={item.id} _text={item.text} bar={item.bar} cta={item.cta} isOpen={isOpen} setOpen={setOpen} colorNav={colorNav} setColorNav={setColorNav}/>
                ))}
            </div>
        </div>
    )
}