import { InView } from 'react-intersection-observer';
import ReactVisibilitySensor from 'react-visibility-sensor';

import './service.css';

export const Service = (props) => {
    return (
        <InView>
            {({inView, ref, entry}) => (
                <div ref={ref} className={"service-one service animate__animated " + (inView ? "animate__fadeIn" : "animate__fadeOut") + " animate__slow"}>
                    <div className="service-img">
                        <img src={props.img} alt={props.title} />
                    </div>
                    <div className="service-description">
                        <h2>{props.title}</h2>
                        <p>{props.caption}</p>
                    </div>
                </div>
            )}
        </InView>
    )
}