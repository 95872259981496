import { Container, Row, Col } from "react-bootstrap";

import { BlockHeader } from "../BlockHeader";
import { PortfolioItem } from "./PortfolioItem";
import { CallToAction } from "../CallToAction";
import { Footer } from "../Footer";

import portfolioImg01 from '../../assets/img/portfolio-drakkar.jpg';
import portfolioImg02 from '../../assets/img/portfolio-innobyte.jpg';
import portfolioImg03 from '../../assets/img/portfolio-magno.jpg';
import portfolioImg04 from '../../assets/img/portfolio-jeanduran.jpg';
import portfolioImg05 from '../../assets/img/portfolio-vga.jpg';

import imgPortfolio001 from '../../assets/img/portfolio-innobyte-0001.jpg';
import imgPortfolio002 from '../../assets/img/portfolio-jeanduran-0001.jpg';
import imgPortfolio003 from '../../assets/img/portfolio-drakkar-0001.jpg';
import imgPortfolio004 from '../../assets/img/portfolio-vga-0001.jpg';



import './index.css';
{/*{title:"Distribuciones Magno", text:"SItio web y tienda virtual, para distribuidora de productos en Colombia. <br><br> Instalación, configuración y personalización del diseño, sobre la plataforma Prestashop.", image:portfolioImg03, href:""},*/}
export const Portfolio = () => {

    const _line1 = '<span class="color"> Mi </span> Portafolio';
    const _line2 = 'Algunos de mis <span class="color"> Trabajos';

    const portfolioList = [
        {proyecto:"drakkar", title:"Drakkar Agencia Web", text:"Diseño y desarrollo de sitio web e imagen corporativa. Desarrollado usando HTML5, CSS3, Framework Bootsrtap 5 y JavaScript. <br /> <br /> Recomiendo visitarla, es el sitio de mi agencia ;)", image:portfolioImg01, href:"https://drakkarweb.com/", type:"WEB"},
        {proyecto:"jeanduran", title:"Jean Durán y Asociados", text:"Este sitio web para una firma de contadores, se desarrollo totalmente en WordPress con Elementor para la persoanlización. <br><br> Si los contactan diganles que van de mi parte ;) ", image:portfolioImg04, href:"https://www.jdcontadores.com/", type:"WEB"},
        {proyecto:"innobyte", title:"Innobyte Consultant SAS", text:"Desarrollo del sitio web de una consultoria de software. Se realizó tambien el diseño de la identidad corporativa. <br><br> Desarrollado con el Framework Codeigniter de PHP y el Framework Bootstrap 4.", image:portfolioImg02, href:"https://www.innobytecon.com/", type:"WEB"},
        {proyecto:"vga", title:"VGA Galería de Arte", text:"Un sitio web completo, para una galería de arte en Barquisimeto, Venezuela. <br /><br /> Se realizó totalmente con Codeigniter y Bootstrap.", image:portfolioImg05, href:"https://www.vgagaleria.com/", type:"WEB"},
    ]

    const imagesPortfolio = [
        {img:imgPortfolio001, alt:"Innobyte SAS Consultant", proyecto:"innobyte"},
        {img:imgPortfolio002, alt:"Jean Duran y Asociados", proyecto:"jeanduran"},
        {img:imgPortfolio003, alt:"Drakkar Agencia Web", proyecto:"drakkar"},
        {img:imgPortfolio004, alt:"VGA Galería de Arte", proyecto:"vga"},
    ]

    return (
        <div className="contentBlock" id="portfolio">
            <Container>
                <BlockHeader line1={_line1} line2={_line2} initial={true}/>

                <div id="portfolio-content">
                    <Row>
                    {portfolioList.map((item, index) => (
                        <Col key={index} xs={12} sm={6} md={4} lg={3}>
                            <PortfolioItem key={index} item={item} images={imagesPortfolio.filter(image => image.proyecto === item.proyecto)}/>
                        </Col>
                    ))}
                    </Row>
                </div>
            </Container>
            <CallToAction text='Contáctame' title="¿Tienes un proyecto en mente? Te ayudo a hacerlo realidad" target='#contact' />
            <Footer />

        </div>
    )
}