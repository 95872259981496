import { ContactInfoLine } from './ContactInfoLine';
import ReactVisibilitySensor from "react-visibility-sensor";

import './contactInfo.css';


import { ReactComponent as LinkedinLogo } from "../../assets/img/linkedin-logo.svg";
import { ReactComponent as TwitterLogo } from "../../assets/img/twitter-logo.svg";
import { ReactComponent as InstagramLogo } from "../../assets/img/instagram-logo.svg";
import { ReactComponent as MailIcon} from "../../assets/img/mail-icon.svg";
import { ReactComponent as WhatsAppLogo } from "../../assets/img/whatsapp-logo.svg";
import { InView } from 'react-intersection-observer';


export const ContactInfo = () => {

    const contactInfoList = [
        {icon:<WhatsAppLogo />, title:"WhatsApp", text:"+58 424 5267456", href:"https://wa.me/584245267456"},
        {icon:<MailIcon />, title:"Email", text:"me@pablojesus.com", href:"mailto:me@pablojesus.com"},
        {icon:<InstagramLogo />, title:"Instagram", text:"@pablojesusweb", href:"https://www.instagram.com/pablojesusweb/"},
        {icon:<LinkedinLogo />, title:"LinkedIn", text:"/pablojesus", href:"https://www.linkedin.com/in/pablojesus/"},
        {icon:<TwitterLogo />, title:"Twitter", text:"@pablojesusweb", href:"https://twitter.com/pablojesusweb"},
    ]
    return (
        <InView>
        {({inView, ref, entry}) =>
            <div ref={ref} className={"contact-info animate__animated " + (inView ? "animate__fadeInRight animate__fast" :"animate__fadeOut animate__slow")}>
                <div className="contact-info-header">
                    Información de Contacto
                </div>
                <div className="contact-info-content">
                    {contactInfoList.map((item, index) => (
                        <ContactInfoLine key={index} icon={item.icon} title={item.title} text={item.text} href={item.href}/>
                    ))}                
                </div>
            </div>
        }
        </InView>
    )
}