import { BlockHeader } from "../BlockHeader";
import { ContactForm } from "./ContactForm";
import { ContactInfo } from "./ContactInfo";
import { Container, Row, Col } from "react-bootstrap";
import { Footer } from "../Footer";

import "./index.css";

export const Contact = () => {

    const _line1 = 'Contácta<span class="color">me</span>';
    const _line2 = '<span class="color"> Trabajemos </span> juntos.';


    const config = {
        api: "https://pablojesus.com/api/contact/",
        title: 'Contactame',
        description: 'Escribeme tu consulta, y te contáctaré en las proximas 48 horas.',
        successMessage: 'Gracias por cescribirme, te contactaré en las próximas 24 horas.',
        errorMessage: 'Por favor rellena todos los datos del formulario.',
        fields:{
          firstName: '',
          lastName: '',
          email: '',
          msg: ''
        },
        fieldsConfig:  [
         { id: 1, label: 'First Name', fieldName: 'firstName', type: 'text',placeholder:'Your First Name', isRequired: true , klassName:'first-name-field'},
         { id: 2, label: 'Last Name', fieldName: 'lastName', type: 'text', placeholder: 'Your Last Name', isRequired: true , klassName:'last-name-field'},
         { id: 3, label: 'Email', fieldName: 'email', type: 'email', placeholder: ' Your Email', isRequired: true , klassName:'email-field'},
         { id: 4, label: 'Message', fieldName: 'msg', type: 'textarea',placeholder:'Write something.....', isRequired: true , klassName:'message-field'}
        ]
      }

    return (
        <div className="contentBlock" id="contact">
            <Container>
            <BlockHeader line1={_line1} line2={_line2}/>
            <div className="contact-content">
                <Row>
                    <Col xs={12} md={7}>
                        <ContactForm config={config} />
                    </Col>
                    <Col xs={12} md={5}>
                        <ContactInfo />
                    </Col>
                </Row>
            </div>
            </Container>
            <Footer />
        </div>
    )
}