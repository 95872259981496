import { BlockHeader } from "../BlockHeader";
import { Button } from "../Button";
import { Container, Row, Col } from "react-bootstrap";
import { InView } from 'react-intersection-observer';

import imgAbout from "../../assets/img/about--image.jpg";

import cvPdf from '../../assets/docs/curriculum-ES-VE.pdf';

export const AboutContent = () => {
    const _line1 = 'Acerca de <span class="color">mi</span>';
    const _line2 = 'Conoce<span class="color">me.</span>';

    return (
        <div id="about-content">
            <Container>
                <BlockHeader line1={_line1} line2={_line2} initial={true}/>
                <div className="about-main">
                    <Row>
                        <InView>
                            {({inView, ref, entry}) => (
                                <Col ref={ref} xs={12} md={6} xl={7} className={"about-first-paragraph animate__animated " + ( inView ? "animate__slideInLeft" : "animate__fadeOut")}>
                                    <span className="about-first-line">
                                        Soy un 
                                        <span className="color"> desarrollador web </span>
                                        dedicado a crear <span className="color">experiencias digitales </span> innovadoras.
                                    </span>
                                    <span className="about-second-line"> Con 15 años de experiencia como desarrollador web, he adquirido las habilidades y conocimientos necesarios para que tu proyecto sea un éxito. Disfruto hacer proyectos creativos y explorar nuevas técnicas para mejorar el resultado final.</span>
                                    <div className="cv">
                                        <Button href={cvPdf} caption="Descargar Resumen" target="_blank"/>
                                    </div>
                                </Col>
                            )}
                        </InView>
                        <InView> 
                        {({inView, ref, entry}) => (
                            <Col ref={ref} xs={12} md={6} xl={5} className={"about-img animate__animated " + (inView ? "animate__slideInRight" :"animate__fadeOut")}>
                                <img className="img-fluid" src={imgAbout} alt="Pablo Jesús portratit" />
                            </Col>
                        )}
                        </InView>
                    </Row>
                </div>
                </Container>
            </div>
    )
}