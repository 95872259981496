import './index.css';
import CursorFunctions from '../Cursor/CursorFunctions';

export const Button = (props) => {
    return (
        <a className="myButton" href={props.href} target={props.target} onMouseEnter={CursorFunctions.cursorHover} onMouseLeave={CursorFunctions.cursor}>
            
                {props.caption}
            
        </a>
    )
}