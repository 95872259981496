import { BlockHeader } from '../BlockHeader';
import { Service } from './Service.js';
import { Container, Row, Col } from 'react-bootstrap';

import imgService1 from '../../assets/img/coding.png';
import imgService2 from '../../assets/img/instagram.png';
import imgService3 from '../../assets/img/bulb.png';

import './services.css';

export const Services = () => {

    const _line1 = '<span class="color">Mis</span> Servicios';
    const _line2 = 'Que puedo hacer <span class="color">por ti</span>';

    const servicesList = [
        {
        title:"Gestión de Redes Sociales", 
        caption: "Administraré los canales de redes sociales de tu marca y experimentaré todas las posibilidades para mejorar la visibilidad, aumentar las ventas y obtener el mejor retorno de inversión posible, todo esto trabajando en estrecha colaboración con tu equipo para diseñar una estrategia personalizada.", 
        img:imgService2
        },
        {
        title:"Desarrollo Web", 
        caption: "El desarrollo web es mi especialidad. Me apasiona crear sitios web y también crearé el tuyo. Con un enfoque moderno y ágil para el desarrollo web, me enorgullezco de producir sitios web de alta calidad que se ajusten a tu presupuesto. Y no te preocupes por la escalabilidad; ¡Estoy aquí para ayudarte con eso también!",
        img:imgService1
        },
        {
        title:"Diseño de Marca", 
        caption: "Un servicio especializado en diseño gráfico, branding, logos, identidad visual y otras aplicaciones modernas. Me ocupo de todos los aspectos de las necesidades de comunicación visual de tu marca y me aseguro de que esté comunicando su mensaje a través de un diseño dinámico y funcional.", 
        img:imgService3
        },
    ]

    return (
        <div id="services">
            <Container>
            <BlockHeader line1={_line1} line2={_line2}/>
                    <Row className="services-content">
                    {servicesList.map((item, index) => (
                        <Col key={index} xs={12} sm={12} md={4} >
                            <Service title={item.title} caption={item.caption} img={item.img}/>
                        </Col>
                    ))}
                    </Row>
            </Container>
        </div>
    )
}