
import { gsap } from "gsap";

const cursorHover = (e) => {
    gsap.to( ".cursor",{
        scale:1.4,
        opacity:1
    })
}

const cursor = (e) => {
    gsap.to( ".cursor", {
        scale:1,
        opacity:.6
    }) 
} 
    export default {cursorHover, cursor}

