import { Button as MyButton} from '../Button';
import { InView } from 'react-intersection-observer';

import React, {useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Modal, Button} from 'react-bootstrap';

import { Carousel as MyCarousel} from '../Carousel';


import { Row, Col } from 'react-bootstrap';

import 'animate.css';
import './portfolioItem.css';

export const PortfolioItem = (props) => {
    const [show, setShow] = useState(0);
    const handleClose = () => setShow(0);
    const handleShow = () => setShow(1);
    return (
        
        <InView>
            {({inView, ref, entry}) => (
                <div ref={ref} className={"portfolio-item animate__animated " + (inView ? "animate__fadeInUp animate__fast" : "animate__fadeOut animate__slow" )}>
                    <div className="square"> 
                        <div className="content"> 
                            <img className='img-portfolio' src={props.item.image} alt={props.item.title} />
                            <div className="info-portfolio">
                                <div className='portfolio-title'>
                                    <h3>{props.item.title}</h3>
                                    <h4>{props.item.type}</h4>
                                </div>
                                <div className="portfolio-cta">
                                    <Button className="myButton" onClick={handleShow}>
                                        Ver Mas
                                    </Button>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <Modal show={show} onHide={handleClose} size="lg">
                        <Modal.Body>
                            <div className="carouselWraper">
                                <MyCarousel images={props.images}/>
                                <Modal.Title>{props.item.title} <span>{props.item.type}</span></Modal.Title>
                            </div>

                            <Row>
                                <Col xs={12} lg={8} xl={9}>
                                    <div className="carouselDescription">
                                        <p dangerouslySetInnerHTML={{__html: props.item.text}}></p>
                                    </div>
                                </Col>
                                <Col className="modalCTA" xs={12} lg={4} xl={3}>
                                    <MyButton caption="Ver Proyecto" href={props.item.href} target="_blank"/>
                                    <Button className="myButton" onClick={handleClose}>
                                        Cerrar
                                    </Button>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                </div>
            )}
        </InView>
            
    )
}