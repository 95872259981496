import { useState, useEffect } from "react";

import { Container } from "react-bootstrap";

import { IconBar } from '../IconBar';

import { gsap } from "gsap";

import headerImg from "../../assets/img/profile.jpg";

import { ReactComponent as LinkedinLogo } from "../../assets/img/linkedin-logo.svg";
import { ReactComponent as TwitterLogo } from "../../assets/img/twitter-logo.svg";
import { ReactComponent as InstagramLogo } from "../../assets/img/instagram-logo.svg";
import { ReactComponent as MailIcon} from "../../assets/img/mail-icon.svg";
import { ReactComponent as WhatsAppLogo } from "../../assets/img/whatsapp-logo.svg";


import './index.css';



export const Header = (props) => {

    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const toRotate = [ " Web Developer", " Web Designer", " Freelancer", " Digital Creative" ];
    const [text, setText] = useState('');
	const [delta, setDelta] = useState(200 - Math.random() * 100);
    const period = 1200;

    const tick = () => {
		let i = loopNum % toRotate.length;
		let fullText = toRotate[i];
		let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

		setText(updatedText);

		if (isDeleting) {
			setDelta(prevDelta => prevDelta/2)
		}

		if (!isDeleting && updatedText === fullText) {
			setIsDeleting(true);
			setDelta(period);
		} else if (isDeleting && updatedText === '') {
			setIsDeleting(false);
			setLoopNum(loopNum + 1);
			setDelta(100);
		}
	}

    useEffect(() => {
		let ticker = setInterval(() => {
			tick();
		}, delta)
		return () => { clearInterval(ticker) };
	}, [text])

	

    const links = [
        {href:"https://www.linkedin.com/in/pablojesus/", icon:<LinkedinLogo />, alt:"LinkedIn Logo"},
        {href:"https://twitter.com/pablojesusweb", icon:<TwitterLogo />, alt:"Twitter Logo"},
        {href:"https://www.instagram.com/pablojesusweb/", icon:<InstagramLogo />, alt:"Instagram Logo"},
        {href:"mailto:me@pablojesus.com", icon:<MailIcon />, alt:"Email Icon"},
        {href:"https://wa.me/584245267456", icon:<WhatsAppLogo />, alt:"Phone Icon"},
    ];

    function handleClick () {
        props.setColorNav('ligth');
        gsap.to('.contentBlock',{display:"none"});
        gsap.to('#breaker',{display:"block"});
        gsap.to('#breaker-two',{display:"block",delay:.1});
        gsap.to('#breaker',{display:"none",delay:1});
        gsap.to('#breaker-two',{display:"none",delay:1.5});  
        gsap.to('#contact',{display:"block",delay:.7});
    }

    return (
        //Header
        
        <div className="contentBlock header" id="header">
                

            <Container >

                {/*header image*/}
                <div className="header-image">
                    <img src={headerImg} alt="Header Img" />
                </div>

                <div className="header-content">
                    <div className="header-content-box">
                    
                        <div className="firstline">Pablo <span className="color"> Jesús</span></div>
                        <div className="secondline">
                            <span className="color"> Soy </span>
                            <span className="txt-rotate">{text}</span>
                            <span className="slash"> |</span>
                        </div>
                    </div>
                    
                </div>

                <div className="headerCTA">
                    ¿Necesitas mejorar tu presencia digital? <br />
                    <a className="headerCTA-link" href="#" onClick={handleClick}>Contáctame</a>
                </div>

               
                
                <IconBar _class="social-media-links" _classLinks="social-media" links={links} />

            </Container>    
           
        </div>
        
    );
}