import { InView } from 'react-intersection-observer';

import 'animate.css';
import './index.css';

export const BlockHeader = (props) => {

    return (
            <InView>
                {({inView, ref, entry}) => (
                <div ref={ref} className={inView ? "block-header animate__animated animate__fadeInDown animate__fast" : "block-header animate__animated animate__fadeOut animate__slow "}>
                    <div dangerouslySetInnerHTML={{__html: props.line1}}></div>
                    <div className="block-header-caption" dangerouslySetInnerHTML={{__html: props.line2}}>
                    
                    </div>
                </div>
                )}
            </InView>
    )
}