import { useEffect, useState } from 'react';
import { gsap } from "gsap";
import CursorFunctions from "../Cursor/CursorFunctions";


import "./index.css";

export const IconLink = (props) => {

    return (
        <a href={props.href} className={props._class} target="_blank" onMouseEnter={CursorFunctions.cursorHover} onMouseLeave={CursorFunctions.cursor}>
            {props.icon}
        </a>
    )
}