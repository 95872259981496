import React from 'react';
import {
    MDBCarousel,
    MDBCarouselInner,
    MDBCarouselItem,
    MDBCarouselElement,
    MDBContainer,
  } from "mdb-react-ui-kit";

import './index.css';


export const Carousel = (props) => {
    return (
        
        <MDBCarousel showControls showIndicators>
             {/*
            {props.images.map((item, index) => (
                <MDBCarouselItem 
                        className={('w-100 d-block ----') + (index) }
                        itemId={index}
                        src={item.img}
                        alt={item.alt} />
                
            ))} 
             */}

            {props.images.map((item, index) => (
                <div className={"carousel-item " + (index==0 ? "active" : "")} index={index}>
                    <img src={item.img} className="d-block w-100" alt={item.alt}/>
                </div>
            ))} 

         {/*
            <MDBCarouselItem
                className='w-100 d-block'
                itemId={3}
                src='https://mdbootstrap.com/img/new/slides/043.jpg'
                alt='...'
            />
         */}
        </MDBCarousel>      
       


        
    )
}