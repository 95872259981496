import { IconLink } from "./iconLink.js";
import "./index.css";

export const IconBar = (props) => {
    return (
        <div className={props._class}>
            

            {props.links.map((_link, index) => (
                <IconLink key={index} _class={props._classLinks} href={_link.href} icon={_link.icon} alt={_link.alt} iconSvg={_link.iconSvg}/>
            ))}

        </div>
    )
}