import { Container, Row } from "react-bootstrap";
import { BlockHeader } from "../BlockHeader";
import { Skill } from './Skill';

import './skills.css';

export const Skills = () => {
    
    const _line1 = 'Mis <span class="color"> Habilidades</span>';
    const _line2 = '<span class="color">Algunas </span> de ellas';

    const skillsList = [
        {text:"HTML/CSS", value:"95"},
        {text:"Javascript", value:"90"},
        {text:"React JS", value:"85"},
        {text:"PHP", value:"90"},
        {text:"JQuery", value:"95"},
        {text:"Wordpress", value:"90"},
    ]

    return (
        <div id="skills">
            <Container>
            
            <BlockHeader line1={_line1} line2={_line2}/>

            

            <div className="skills-content">
            <Row>
                {skillsList.map((item, index) => (
                    <Skill key={index} text={item.text} value={item.value} visible={true}/>
                ))}
            </Row>
            </div>


            </Container>

        </div>
        
        
        
    )
}