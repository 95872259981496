
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import './styles/Variables.css';
import './App.css';

import { useState } from "react";

import { Breaker } from './components/Breaker'
import { NavBar } from './components/NavBar'
import { Cursor } from './components/Cursor'

import { Header } from './components/Header';
import { About } from './components/About';
import { Portfolio } from './components/Portfolio';
import { Contact } from './components/Contact';



function App() {

  
  const [colorNav, setColorNav] = useState("dark");

  return (
    <div className="App">
      <Breaker id="breaker" />
      <Breaker id="breaker-two" />
      
      <NavBar colorNav={colorNav} setColorNav={setColorNav}/>
      <Cursor />
      <Header colorNav={colorNav} setColorNav={setColorNav}/>
      <About />
      <Portfolio />
      <Contact />
      
    </div>
  );
}

export default App;
