import { AboutContent } from "./AboutContent";
import { Services } from "./Services";
import { Skills } from "./Skills";
import { Footer } from "../Footer";
import { CallToAction } from "../CallToAction";

import './index.css';

export const About = () => {
    return (
        <div className="contentBlock about" id="about">
            <AboutContent />
            <Services />
            <Skills />
            <CallToAction text='Contáctame' title="¿Tienes un proyecto en mente? Te ayudo a hacerlo realidad" target='#contact' />
            <Footer />
        </div>
    )
}