import {useState} from "react";
import { useRef } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import Form from 'react-bootstrap/Form';
import validator from "validator";

import './contactForm.css';
import { InView } from "react-intersection-observer";
import { Button, Col, Row } from "react-bootstrap";

export const ContactForm = (props) => {

    const [mailSent, setmailSent] = useState(false);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({});
    const [errorMsg, setErrorMsg] = useState("");
    const [emailError, setEmailError] = useState(null);

    const captcha = useRef(null);
   
    /**
    * @function handleFormSubmit
    * @param e { obj } - form event
    * @return void
    */
    const handleFormSubmit = e => {
      e.preventDefault();

      if (!emailError) {
        if (captcha.current.getValue()) {
          
          axios({
            method: "post",
            url: "https://pablojesus.com/api/contact/",
            headers: { "content-type": "application/json" },
            data: formData
          })
            .then(result => {
              if (result.data.sent) {
                setmailSent(result.data.sent)
                setError(false)
                cancelCourse()
                captcha.current.reset();
              } else {
                setErrorMsg(errorMessage)
                setError(true)
              }
            })
            .catch(error => setError( error.message ));

        } else {
          setError(true);
          setErrorMsg("Indique que no es un robot");
        }
      }


    };
    /**
      * @function handleChange
      * @param e { obj } - change event
      * @param field { string } - namve of the field
      * @return void
      */
     const handleChange = (e, field) => {
      let value = e.target.value;
     
      setFormData({
        ...formData,
        [field]: value,
      });
    };

    const cancelCourse = () => { 
      document.getElementById("myForm").reset();
    }

    const onChange = () => {
      console.log(captcha.current.getValue());
    }

    const validateEmail = (e) => {
      var email = e.target.value
    
      if (validator.isEmail(email)) {
        setEmailError(false)
      } else {
        setEmailError(true)
      }
    }

    
    

    const { title,description, successMessage, errorMessage, fieldsConfig } = props.config;

    return (
      <InView partialVisibility>
        {({inView, ref, entry}) => (
          <div ref={ref} className={"contact-form animate__animated " + (inView ? "animate__fadeInLeft animate__fast" :"animate__fadeOut animate__slow")}>
              <div className="form-header">
                  Enviame un mensaje
              </div>
              <Form id="myForm" action="#" >
                <Row>
                  <Form.Group as={Col} xs={12} lg={6} className="mb-3" >
                    <Form.Control id="name" type="text" placeholder="Nombre" className="input-name" onChange={e => handleChange(e, "firstName")}/>
                  </Form.Group>
                
                  <Form.Group as={Col} xs={12} lg={6} className="mb-3" >
                    <Form.Control id="email" type="email" placeholder="Email"  className="input-name" onChange={e => handleChange(e, "email")} onBlur={validateEmail} />
                    {emailError && <span className="emailError">Introduzca un email válido</span> }
                  </Form.Group>

                  <Form.Group as={Col} lg={12} className="mb-3" >
                    <Form.Control type="text" id="subject" placeholder="Asunto" className="input-subject" onChange={e => handleChange(e, "asunto")}/>
                  </Form.Group>

                  <Form.Group as={Col} lg={12} className="mb-3" >
                    <Form.Control as="textarea" rows={6} id="msg" placeholder="Tu mensaje" className="input-subject" onChange={e => handleChange(e, "msg")} />                  
                  </Form.Group>
                  <Col xs={12} className="divCaptcha">
                    
                    <ReCAPTCHA 
                      ref={captcha}  
                      sitekey="6LfKvF8UAAAAALFAcg1XKhI_i0rqDXxtUFrg9L1R" onChange={onChange}/> 
                      
                  </Col>
                  <Col xs={12}>
                    <Button className="myButton" type="submit" id ="submit" onClick={e => handleFormSubmit(e)}>
                      Enviar Mensaje
                    </Button>
                  </Col>

                  <Col xs={12}>
                    <div className="formMessage">
                        {mailSent && <div className="sucsess">{successMessage}</div>}
                        {error && <div className="error">{errorMsg}</div>}
                    </div>
                  </Col>

                </Row>
              </Form>
                  
          </div>
        )}
      </InView>
    )
}