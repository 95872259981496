import { Col } from "react-bootstrap";
import './skill.css';
import { InView } from "react-intersection-observer";

export const Skill = (props) => {

    function mystyle(inView) {
        return inView ? {width: props.value + "%"} : {width: 10 + "%"};
    }

    return (
        <Col sm={6}>
            <div className="skill-html skill">
                <div className="skill-text">
                    <div className="html">
                        {props.text}
                    </div>
                </div>
                <InView>
                {({inView, ref, entry}) => ( 
                    <div ref={ref} className="html-prog wow prog">
                        <div className="html-progress wow" style={mystyle(inView)}>{props.value}%</div>
                    </div>
                )}
                </InView>
            </div>   
        </Col>
    )
}