
import { gsap } from "gsap";
import CursorFunctions from "../Cursor/CursorFunctions";


import './index.css'



export const CallToAction = (props) => {
    function handleClick () {

        gsap.to('.contentBlock',{display:"none"});
           
        gsap.to('#breaker',{display:"block"});
        gsap.to('#breaker-two',{display:"block",delay:.1});
        gsap.to('#breaker',{display:"none",delay:1});
        gsap.to('#breaker-two',{display:"none",delay:1.5});  
        gsap.to(props.target,{display:"block",delay:.7});
    
    }

    return (
        <div className='divCTA'>
            <h3>{props.title}</h3>
            <a href="#" onClick={handleClick} onMouseEnter={CursorFunctions.cursorHover} onMouseLeave={CursorFunctions.cursor}>{props.text}</a>
        </div>
    )
}