import './contactInfoLine.css';

export const ContactInfoLine = (props) => {
    return (
        <div className="contact-info-content-line">
            {/*<img src={props.icon} className="icon" alt={props.title + "-icon"} />*/}
            <div className="icon">{props.icon}</div>
            <div className="contact-info-icon-text">
                <h6>{props.title}</h6>
                <a href={props.href} target="_blank">{props.text}</a>
            </div>
        </div>
    )
}