import { useEffect, useState } from 'react';
import { gsap } from "gsap";

import './index.css';

export const Cursor = () => {

    const [mousePos, setMousePos] = useState({x: 0, y: 0});

    useEffect(() => {
        const handleMouseMove = (e) => {
            setMousePos({ x: e.clientX, y: e.clientY });
            gsap.to( ".cursor", {
                x : e.clientX,
                y : e.clientY,
                stagger:.002
               })
        };
        
        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener(
                'mousemove',
                handleMouseMove
            );
        };
    }, []);

    return (
        <div className="cursor">
            
        </div>
    )
}