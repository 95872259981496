import './index.css';

import imgCopy from '../../assets/img/copyright.png';

export const Footer = () => {
    return (
        <div className="footer">
             <div className="footer-text">
                <img src={imgCopy} alt="copyright-img" className="images"  /> Pablo Jesús
             </div>
        </div>
    )
}